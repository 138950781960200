body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    overflow-x: hidden;
}

header {
    background: #333;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

header a {
    color: white;
    text-decoration: none;
    margin-left: 20px;
}

header .logo {
    font-size: 24px;
    font-weight: bold;
}

.hero {
    
    background: linear-gradient(
        rgba(0, 0, 0, 0.4), 
        rgba(0, 0, 0, 0.4)
      ), url("./assets/header.jpg");
    background-size: cover;
    background-position: 20vh -5vh;
    color: white;
    text-align: center;
    padding: 20vh 2vw;
}

.hero h1 {
    font-size: 48px;
    margin: 0;
}

.hero .cta-btn {
    background: #ff9800;
    color: white;
    padding: 15px 30px;
    text-decoration: none;
    font-weight: bold;
    margin-top: 20px;
    display: inline-block;
    border-radius: 5px;
}

.hero .cta-btn.secondary {
    background: #4CAF50;
    margin-left: 10px;
}

.section {
    padding: 50px 20px;
    text-align: center;
}

.section h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.facility-icons, .testimonials {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.facility-icons div, .testimonials div {
    width: 250px;
}

.facility-icons div i, .testimonials div p {
    font-size: 24px;
}

.facility-icons div {
    background: #f2f2f2;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.facility-icons div i {
    font-size: 50px;
    margin-bottom: 10px;
}

.cta-btn:hover {
    opacity: 0.9;
}

footer {
    background: #333;
    color: white;
    padding: 20px;
    text-align: center;

    position: relative;
    bottom: 0;
    width: 100vw;
}

footer .social-icons a {
    margin: 0 10px;
    color: white;
    text-decoration: none;
}

.cta-btn {
    display: inline-block;
}

/* ReservationPage */
.reservationPage {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100vw;
    min-height: 90vh;

    margin-top: 8vh;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    max-width: 80vw;
    margin-bottom: 10px;
}

.reservationCard {
    width: 23vw;
    height: 15vh;
    margin: 1vh 1vw;
    
    background-color: #eee; 
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.reservationCard h2 {
    margin: 0.3vh 0.3vw;

    font-size: max(1.5vw, 1.7vh);
}

.reservationCard h3 {
    margin: 0.3vh 0.6vw;

    font-size: max(1.2vw, 1.4vh);
    text-wrap: nowrap;
}

.contactPage button {
    background-color: #333333;
    color: white;
    transition-duration: 0.3s;
    border: 2px solid #333333;
    padding: 5px;
    border-radius: 5px;
}
  
.contactPage button:hover {
    background-color: white;
    border: 2px solid #333333;
    color: black;
}

.compPage {
    padding: 10px;
  }
  
.compPage button {
    background-color: #4695D6;
    color: white;
    transition-duration: 0.3s;
    border: 2px solid #4695D6;
    padding: 5px;
    border-radius: 5px;
}
  
.compPage button:hover {
    background-color: white;
    border: 2px solid #4695D6;
    color: black;
}

